import React, {useState, isValidElement, useCallback, useEffect } from 'react';
import { Auth, Stack, FormLayout, Button, Modal, Badge, Tooltip, Icon, ButtonGroup, Popover, ActionList, TextField } from "admin-frontend";
import {
  LanguageMinor,
  RiskMajor
} from 'admin-frontend';


export function localeFormat(locale, value) {
  return typeof(value) == "object" ? (value[locale ? locale.code : Object.keys(value).sort()[0]] || value["en"]) : value;
}

export function LocalizedBadgeList({ locale = "en", values, maxToDisplay = 2, emptyTooltip }) {
  if (!values || values.length == 0) {
    return (<ButtonGroup>
      <span>None</span>
      {emptyTooltip && <Tooltip content={emptyTooltip} dismissOnMouseOut>
        <Icon source={RiskMajor} color="warning"/>
      </Tooltip>}
    </ButtonGroup>);
  }
  return (<Stack>
    {values.slice(0, maxToDisplay).map((value, idx) => (isValidElement(value) ? React.cloneElement(value, { key: `badge-${idx}` }) : (<Badge key={`badge-${idx}`}>{localeFormat(locale, value)}</Badge>)))}
    {values.length > maxToDisplay && (<Tooltip key={`tooltip-more`} content={values.slice(maxToDisplay).map((value) => localeFormat(locale,  isValidElement(value) ? value.props.children : value)).join(", ")}>
      <Badge key={`badge-more`}>+ {values.length - maxToDisplay} more...</Badge>
    </Tooltip>)}
  </Stack>);
}

export function LocalizedFieldModal({ title, value, onSubmit, onClose }) {
  const [profile] = Auth.useProfile();
  const [localizations, setLocalizations] = useState(profile.shop.locales.map((e) => typeof(value) == "object" ? value[e.code] : value));
  return (
      <Modal
        open={true}
        onClose={onClose}
        title={title}
        primaryAction={{
          content: "Save",
          onAction: () => onSubmit(Object.fromEntries(profile.shop.locales.map((e, idx) => [e.code, localizations[idx]] )))
        }}
        secondaryActions={[{ content: "Discard", onAction: onClose }]}
      >
        <Modal.Section>
          <FormLayout>
          {profile.shop.locales.map((locale, idx1) => (
            <TextField label={locale.name + " (" + locale.code + ")"} value={localizations[idx1]} onChange={(val) => {
              setLocalizations(localizations.map((e, idx2) => (idx1 === idx2 ? val : e)));
            }}/>
          ))}
          </FormLayout>
        </Modal.Section>
      </Modal>
  );
}

export function LocalizedTextField({
  value,
  label,
  disabled,
  placeholder,
  locales,
  locale = null,
  onChange,
  error
}) {
  const [languageDialogActive, setLanguageDialogActive] = useState(false);
  const [profile] = Auth.useProfile();
  return [(<TextField
    disabled={disabled}
    placeholder={placeholder}
    label={label}
    error={error}
    key={`${label}-textfield`}
    value={typeof(value) === "object" ? (value[locale ? locale.code : Object.keys(value)[0]] || value["en"]) : value}
    onChange={(val) => {
      onChange(typeof(value) === "object" ? { ...value, [locale ? locale.code : Object.keys(value)[0]]: val } : (locale ? { [locale.code]: val } : val));
    }}
    connectedRight={!locale && (locales ?? profile?.shop?.locales)?.length > 1 && (<Button disabled={disabled} square onClick={() => {
      setLanguageDialogActive(true);
    }}><Icon source={LanguageMinor}/></Button>)}
  />),
  (languageDialogActive && <LocalizedFieldModal key={`${label}-modal`} title={label} value={value} onClose={() => setLanguageDialogActive(false)} onSubmit={(value) => {
    onChange(value);
    setLanguageDialogActive(false);
  }} />)];
}

export function getLocaleSwitcherOption({ locale, setLocale, noAllLanguages, disabled = false, profile }) {
  if (profile && profile.shop.locales && profile.shop.locales.length > 1) {
    const selectedLocale = locale && typeof(locale) == 'string' ? profile.shop.locales.filter((l) => l.code == locale)[0] : locale;
    return {
      title: (<Stack><Icon size="small" source={LanguageMinor}/><span>{selectedLocale ? (selectedLocale.name + " - " + selectedLocale.code) : "All Languages"}</span></Stack>),
      actions: (selectedLocale && !noAllLanguages ? [{ content: "All Languages", onAction: () => setLocale(null)}] : []).concat(profile.shop.locales.map((l) => { return {
        content: l.name + " - " + l.code,
        onAction: () => setLocale(l),
        disabled: disabled
      } }))
    };
  }
  return null;
}

export function LocaleSwitcher({ locale, setLocale, noAllLanguages, disabled }) {
  const [profile] = Auth.useProfile();
  const localeSwitcherOption = getLocaleSwitcherOption({ locale, setLocale, noAllLanguages, disabled, profile });
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const activator = localeSwitcherOption && (<Button onClick={toggleActive} disclosure>{localeSwitcherOption.title}</Button>);

  useEffect(() => {
    if (profile && profile.shop && profile.shop.locales && !locale)
      setLocale(profile.shop.locales[0]);
  }, [profile, locale]);

  return (activator && (
    <Popover
      active={active}
      activator={activator}
      autofocusTarget="first-node"
      onClose={toggleActive}
    >
      <ActionList
        actionRole="menuitem"
        items={localeSwitcherOption.actions}
      />
    </Popover>
  ));
}
