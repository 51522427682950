import React, { useState, useEffect, useCallback, useMemo } from "react";
import { 
  Checkbox,
  Subheading,
  Heading,
  Spinner,
  MultiTable, 
  Auth, 
  Tooltip,
  OptionList,
  ChoiceList,
  Icon,
  Tag,
  useRedirect,
  Button,
  Page as OldPage,
  Stack,
  Layout,
  Card,
  FormLayout,
  rearrangeArray,
  TextField,
  toDateTime,
  toLocaleDate,
  toLocaleDateTime,
  parseUTCDateTime,
  Modal,
  Select,
  CircleInformationMajor, 
  SearchMajor, 
  MobilePlusMajor
} from "admin-frontend";
import { useParams } from "react-router-dom";
import { Page } from "../../components/Page";
import { LocalizedTextField, LocalizedBadgeList, localeFormat } from "../../components/Localization";
import { useSpotAPI } from "../../components/API";
import { productFields, titleCase, convertFromFrontend, SearchField, FieldSpecifier, arrayify } from "../../components/Fields";
import { SwatchSelector, SwatchText } from "../Swatches";
import { DateField, getRelativeDateLabel } from "../../components/DateFields";
import { useLocation } from "react-router-dom";
import { useQuickStartStatus, useTheme } from "../../components/StatusContext";
import { useSpotFetch } from "../../useSpotFetch";
import { NotFound } from "../../NotFound";

export function convertRangeToString(from, to) {
  if (from != null && to != null)
    return from + "-" + to;
  else if (from != null)
    return from + "+";
  return "-" + to;
}

export function convertStringToRange(str) {
  const groups = /^\s*(\-)?(\-?\w+\.?\d*)(\+)?\s*(?:\-\s*(\-?\w+\.?\d*))?\s*$/.exec(str);
  let from = groups && ((groups[1] != "-" && groups[2] >= 0) || groups[4]) ? groups[2] : null;
  let to = groups && (from != null ? groups[4] : groups[2]);
  return [from, to];
}


export function ValuePicker({ field, search, value, onChange, locales, setOptions, ignore, options, type, relativeDates }) {
  const [pickingValue, setPickingValue] = useState({ });
  const [isLoading, setIsLoading] = useState(false);
  const spotAPI = useSpotAPI();
  useEffect(() => {
    if (field && type != "numeric" && type != "date") {
      if (field[0] === "collection") {
        setIsLoading(true);
        spotAPI.s().search(search).rows(100).targets(["collections"]).e().done((collections) => {
          setOptions(collections.map((v) => ({ label: v.title, value: v.handle })));
          setIsLoading(false);
        });
      } else {
        setIsLoading(true);
        let totalValues = {};
        let totalRequests = 0;
        const iterableLocales = (locales || ["en"]);
        iterableLocales.forEach((l) => {
          spotAPI.s().options([convertFromFrontend(search ? [...field, "^", search] : field)], true, "exists").rows(0).locale(l).e().done((products, count, options) => {
            setIsLoading(false);
            let values = options["options"][0][field[0]];
            if (field[0] === "product-metafield" || field[0] === "variant-metafield")
              values = values[field[1]][field[2]];
            else if (field[0] === "option" || field[0] === "product-custom-field" || field[0] === "variant-custom-field")
              values = values[field[1]];
            totalValues = { ...totalValues, ...Object.fromEntries(Object.keys(values).map((v) => ([v, { label: v, value: v }]))) };
            totalRequests++;
            if (totalRequests == iterableLocales.length)
              setOptions(Object.values(totalValues).toSorted((a,b) => a.label.localeCompare(b.label)));
          });
        });
      }
    }
  }, [search, field, locales, type]);
  const ignoreHash = Object.fromEntries((ignore || []).map((i) => [i, true]));
  const filteredValues = options && options.filter((o) => !ignoreHash[o.value]);

  if (type == "numeric" || type == "date") {
    const [from, to] = convertStringToRange(value);
    return (<Stack alignment="flexEnd">
      {type == "date" && <Stack.Item fill><DateField relative={relativeDates} label="From" onChange={(value) => onChange(convertRangeToString(relativeDates ? value : Math.floor(parseUTCDateTime(value).getTime() / 1000), to))} value={!from ? new Date() : (from && relativeDates ? from : new Date(from * 1000))} /></Stack.Item>}
      {type == "date" && <Stack.Item fill><DateField relative={relativeDates} label="To" onChange={(value) => {
        return onChange(convertRangeToString(from, relativeDates ? value : Math.floor(parseUTCDateTime(value).getTime() / 1000)));
      }} value={!to ? new Date() : (to && relativeDates ? to : new Date(to * 1000))} min={from && relativeDates ? from : new Date(from * 1000)}/></Stack.Item>}
      {type == "numeric" && <Stack.Item fill><TextField type="number" label="From" onChange={(value) => onChange(convertRangeToString(value, to))} value={from}/></Stack.Item>}
      {type == "numeric" && <Stack.Item fill><TextField type="number" label="To" onChange={(value) => onChange(convertRangeToString(from, value))} value={to} min={from}/></Stack.Item>}
    </Stack>);
  }
  if ((!filteredValues || filteredValues.length == 0) && (search ?? '') == '')
    return <Heading>No {options && options.length > 0 ? "unused" : ""} values found.</Heading>;
  return <OptionList allowMultiple onChange={onChange} selected={arrayify(value).map((v) => typeof(v) == 'object' ? v["*"] : v)} options={
    [
      ...(filteredValues || []), 
      ...(type == "string"  ? [{ label: `${(search ?? '') != '' && 'Starts with ' || ''}Wildcard [${search ?? ''}*]`, value: (search ?? '') + "*" }] : []),
      ...(type == "string" && (search ?? '') != '' && (!filteredValues || filteredValues.filter((v) => v.value == search).length == 0) ? [{ label: `Manual [${search}]`, value: search }] : [])
    ]}
  />
}

export function FacetValueModal({ field, type, onClose, open, value, onChange, onSubmit, relativeDates, ignore, locales, swatches, setSwatches, ...props }) {
  const [options, setOptions] = useState(null);
  const [valueSearch, setValueSearch] = useState(null);
  const [showAllSelectedValues, setShowAllSelectedValues] = useState(false);  
  const productField = productFields.filter((f) => f.handle == field[0])[0];
  const fieldType = type || (productField && productField.type);
  const isString = fieldType == "string" || fieldType == "list" || fieldType == "both" || fieldType == "collection";
  const maxFacetValuesShown = 50;
  const hasSelectedAllFacets = options && value && isString && value.value && arrayify(value.value).filter((v) => typeof(v) != 'object').length === options.filter((v) => v.label.indexOf("Wildcard]") == -1).length;
  const groupSelectedValues = value && value.name != null && value.name != "*";
  return (<Modal
    large
    open={open}
    title={!value || value.idx == null ? "Add Elements" : `Edit ${value.name ? localeFormat(null, value.name) : "Elements"}`}
    onClose={onClose}
    primaryAction={{
      content: !value || value.idx == null ? "Add" : "Modify",
      disabled: (!value || !value.value || value.value.length == 0) || ((groupSelectedValues || !isString) && (value.name == null || value.name == '')),
      onAction: () => { onSubmit(value); onClose(); }
    }}
    secondaryActions={[{ content: "Close", onAction: onClose }]}
    {...props}
  >
    <Modal.Section>
      <FormLayout>
        {(groupSelectedValues || (!isString && fieldType != "bool")) && <LocalizedTextField label="Label" locales={locales} value={value && value.name || ''} onChange={(name) => { onChange({ ...value, name: (name ?? "*") != "*" ? name : undefined }); }}/>}
        {(groupSelectedValues || (!isString && fieldType != "bool")) && <SwatchSelector
          label="Swatch"
          fullWidth
          swatches={swatches}
          setSwatches={setSwatches}
          value={value && value.swatch_id}
          onChange={(swatchId) => { onChange({ ...value, swatch_id: swatchId }); }}
        />}
        {(isString || fieldType === "bool") && value && <Stack vertical>
          <Subheading>SELECTED &nbsp; <Button onClick={() => { onChange({ ...value, value: !hasSelectedAllFacets ? options.map((v) => v.value) : [] })}} size="slim">{hasSelectedAllFacets ? "Select None" : "Select All"}</Button></Subheading>
          {value.value && arrayify(value.value).length > 0 && <Stack spacing="loose">
            {value.value &&
              arrayify(value.value).slice(0, showAllSelectedValues ? 10000000 : maxFacetValuesShown).map((selected, idx) => <Tag key={`${selected}_${idx}`} onRemove={() => { onChange({ ...value, value: arrayify(value.value).filter((v) => v != selected) }); }}>
                {typeof(selected) == "object" && selected["*"] ? selected["*"] : selected}
              </Tag>)}
            {!showAllSelectedValues && value.value && arrayify(value.value).length > maxFacetValuesShown && (
              <Tag onClick={() => { setShowAllSelectedValues(true); }}>+ Show {arrayify(value.value).length - maxFacetValuesShown} More</Tag>
            )}
          </Stack>}
        </Stack>}
        <Stack vertical spacing="loose">
          {isString && <Subheading>ALL VALUES</Subheading>}
          {isString && <SearchField onChange={setValueSearch} value={valueSearch}/>}
          {value && <ValuePicker ignore={ignore} locales={locales} type={isString ? "string" : fieldType} field={field} relativeDates={relativeDates} search={valueSearch} value={value.value} onChange={(v) => {
            // Three types of values:
            // {"name": "dfsadf", "value": ["value1", "value2", ...]
            // {"name": "Rubies", "value": {"*": "Ruby*"}}
            // {"value":{"*":"Gemstone*"}}
            v = v.map((iv) => iv.indexOf("*") == iv.length - 1 ? {"*":iv} : iv);
            onChange({ ...value, value: v })
          }} setOptions={setOptions} options={options}/>}
        </Stack>
      </FormLayout>
    </Modal.Section>
  </Modal>);
}

export function EditFacet() {
  const authFetch = useSpotFetch();
  const redirect = useRedirect();
  const [profile] = Auth.useProfile();
  const [swatches, setSwatches] = useState(null);
  const [facet, setFacet] = useState(null);
  const [groups, setGroups] = useState(null);
  const [editingFacetValue, setEditingFacetValue] = useState(null);
  const [showUnusedValues, setShowUnusedValues] = useState(true);
  
  const { facetId } = useParams();
  const [isChanged, setIsChanged] = useState(false);
  const maxFacetValuesShown = 8;
  const spotAPI = useSpotAPI();

  const searchURL = useLocation().search;
  const params = useMemo(() => new URLSearchParams(searchURL), [searchURL]);
  const themeId = params.get("theme_id");
  const theme = useTheme();

  useEffect(() => {
    if(!groups)
      authFetch("/api/facets/groups", { query: theme || themeId ? { theme_id: themeId || theme.id } : {} }).then((r) => setGroups(r.groups));
  }, [groups, theme])

  useEffect(() => {
    if(!swatches)
      authFetch('/api/swatches').then((r) => setSwatches(r.swatches));
  }, [swatches]);

  useEffect(() => {
    if (!facet) {
      if (facetId == "new")
        setFacet({ values: null, level: "string", type: "product_type" })
      else
        authFetch("/api/facets/" + facetId).then((r) => setFacet({ values: null, ...r.facet }));
    }
  }, [theme]);
  
  if (facet == false)
    return <NotFound/>;

  if (!facet)
    return (<OldPage><Stack alignment="center" distribution="center"><Spinner size="large"/></Stack></OldPage>);
  
  const field = productFields.filter((f) => f.handle == facet.type)[0];
  const fieldType = facet.level || field.type;

  const convertToField = (facet) => {
    if (facet.type.indexOf("metafield") != -1)
      return [facet.type, facet.namespace, facet.key];
    if (facet.type.indexOf("custom") != -1)
      return [facet.type, facet.field];
    if (facet.type.indexOf("option") != -1)
      return [facet.type, facet.option];
    return [facet.type];
  };
  
  return (
    <Page
      localization
      isChanged={isChanged}
      audit={facet && {resource: "Facet", id: facet.id}}
      overrideThemes
      resourceName={{ singular: "facet", plural: "facets" }}
      resourceId={facet.id}
      disableThemes
      title={facet ? localeFormat(null, facet.name) : titleCase(fieldType.replace(/[_-]/g, " "))}
      subtitle={facetId !== "new" ? "Edit Facet" : "New Facet"}
      breadcrumbs={[{ content: "Facets", onAction: () => redirect("/catalog/facets" + (themeId ? "?theme_id=" + themeId : "")) }]}
      onSave={() => authFetch('/api/facets' + (facetId && facetId != "new" ? `/${facetId}` : ''), { json: { ...facet, values: facet.values?.map((v) => ({ name: v.name, value: v.value })) } }).then((r) => { 
        setFacet({ ...facet, ...r }); 
        setIsChanged(false); 
        if (facetId == "new")
          redirect("/catalog/facets/" + r.id + (themeId ? "?theme_id=" + themeId : ""))
      })}
    >
      {(theme, locale) => {
        const sortedFacetValues = facet.values && (typeof(facet.sort) == 'object' && (facet.sort["asc"] == "name" || facet.sort["desc"] == "desc")) ? facet.values.toSorted((a,b) => localeFormat(locale, facet.sort.asc ? a.name : b.name).localeCompare(localeFormat(locale, facet.sort.asc ? b.name : a.name))) : facet.values;
        return (
          <Layout>
            <Layout.Section>
              <Card sectioned>
                <FormLayout>
                  <LocalizedTextField label="Name" locales={facet.locales} locale={locale} value={facet.name} onChange={(val) => {  setIsChanged(true); setFacet({ ...facet, name: val }) }}/>
                  <TextField label="Description" multiline={4} value={facet.description} onChange={(val) => {  setIsChanged(true); setFacet({ ...facet, description: val }); }} placeholder="Optional freeform description for this facet. Does not affect functionality." />
                  <FieldSpecifier label="Catalogue Field" onChange={(value) => { 
                    const field = productFields.filter((f) => f.handle == value[0])[0];
                    const level = field.type == "both" || field.type == "list" ? "string" : field.type;
                    if (value[0].indexOf("metafield") != -1)
                      setFacet({ ...facet, type: value[0], namespace: value[1], key: value[2], values: [], level: level });
                    else if (value[0].indexOf("custom-field") != -1)
                      setFacet({ ...facet, type: value[0], field: value[1], values: [], level: level }) 
                    else if (value[0] == "option")
                      setFacet({ ...facet, type: value[0], option: value[1], values: [], level: level }) 
                    else
                      setFacet({ ...facet, type: value[0], values: [], level: level });
                    setIsChanged(true);
                  }} field={convertToField(facet)}/>
                  {(fieldType === "string" || fieldType === "bool") && (<Stack distribution="fillEvenly" alignment="center">
                    <Checkbox
                      label="Include all values automatically"
                      onChange={(value) => { setFacet({ ...facet, values: value ? undefined : [] }); setIsChanged(true); }}
                      checked={!facet.values}
                    />
                    <Select
                      options={[...(facet.values ? [{ label: "Manual Ordering", value: "manual" }] : []), { label: "Sort Name Ascending", value: "name-asc" }, { label: "Sort Name Descending", value: "name-desc" }, { label: "Sort Count Ascending", value: "count-asc" }, { label: "Sort Count Descending", value: "count-desc" }]}
                      onChange={(value) => { setFacet({ ...facet, sort: value == "manual" ? "manual" : ({ [value.split("-")[1]]: value.split("-")[0] }) }); setIsChanged(true); }}
                      value={facet.sort && typeof(facet.sort) == 'object' ? `${Object.values(facet.sort)[0]}-${Object.keys(facet.sort)[0]}` : facet.sort}
                    />
                  </Stack>)}
                  {fieldType === "date" && <Checkbox
                    label="Use dates relative to present"
                    onChange={(val) => { setFacet({ ...facet, relative: val, values: [] }); setIsChanged(true); }}
                    checked={facet.relative}
                  />}
                  <Checkbox
                    label="Allow only a single value selected at a time"
                    onChange={(val) => { setFacet({ ...facet, single: val });  setIsChanged(true); }}
                    disabled={facet.boundary}
                    checked={facet.single}
                  />
                  {facet && !facet.values && facet.type && productFields.filter((f) => f.handle === facet.type)[0].type === "both" && <Select
                    label="Field type"
                    onChange={(value) => { setFacet({ ...facet, level: value }); setIsChanged(true); }}
                    options={[{label: "String", value: "string"}, {label: "Numeric", value: "numeric"}, {label: "Date", value: "date"}]}
                    value={fieldType}
                  />}
                  {fieldType === "numeric" && (<Stack distribution="fillEvenly">
                    <Checkbox
                      label="Treat numeric value as adjustable range"
                      onChange={(value) => { setFacet({ ...facet, boundary: value ? [null, null] : undefined }); setIsChanged(true); }}
                      checked={facet.boundary}
                    />
                  </Stack>)}
                </FormLayout>
              </Card>
              {facet.boundary && (
                <Card
                  sectioned
                  title="Ranges"
                >
                  <Stack vertical>
                    <Checkbox onChange={(value) => {  setIsChanged(true); setFacet({ ...facet, boundary: [(value ? null : (facet.boundary && facet.boundary[0] != null ? facet.boundary[0] : "0")), (facet.boundary ? facet.boundary[1] : null)] }); }} checked={facet.boundary && facet.boundary[0] === null} label="Automatically determine minimum value"/>
                    <TextField type='number' disabled={facet.boundary && facet.boundary[0] === null} label="Minimum Value" onChange={(value) => {  setIsChanged(true); setFacet({ ...facet, boundary: [value, facet.boundary[1]] }); }} value={facet.boundary && facet.boundary[0]}/>
                    <Checkbox onChange={(value) => {  setIsChanged(true); setFacet({ ...facet, boundary: [(facet.boundary ? facet.boundary[0] : null), (value ? null : (facet.boundary && facet.boundary[1] != null ? facet.boundary[1] : "0"))] }); }} checked={facet.boundary && facet.boundary[1] === null} label="Automatically determine maximum value"/>
                    <TextField type='number' disabled={facet.boundary && facet.boundary[1] === null} label="Maximum Value" onChange={(value) => {  setIsChanged(true); setFacet({ ...facet, boundary: [facet.boundary[0], value] }) }} value={facet.boundary && facet.boundary[1]}/>
                  </Stack>
                </Card>
              )}
              {facet.values && !facet.boundary && (<Card sectioned title="Elements" actions={[{ content: "Add", onAction: () => setEditingFacetValue({ name: null, value: [], swatch_id: null }) }]}>
                <FacetValueModal
                  ignore={showUnusedValues && facet && facet.values.filter((v, idx) => !editingFacetValue || idx !== editingFacetValue.idx).flatMap((v) => v.value)}
                  field={convertToField(facet)}
                  relativeDates={facet.relative}
                  locales={facet.locales}
                  open={editingFacetValue != null}
                  onClose={() => setEditingFacetValue(null)}
                  onChange={(value) => setEditingFacetValue(value)}
                  onSubmit={(value) => {
                    if (value.name) {
                      //value.value = value.value.map((v) => ({ value: typeof(v) == 'object' && v["*"] ? v : [v], name: typeof(v) == 'object' ? undefined : v }))
                      if (value.value.length == 1 && typeof(value.value[0]) == 'object' && value.value[0]["*"])
                        value.value = value.value[0];
                      setFacet({ ...facet, values: [...facet.values.filter((i, idx) => value.idx == null || idx < value.idx), value, ...facet.values.filter((i, idx) => value.idx != null && idx > value.idx)] });
                    } else {
                      // Specific exception to formatting to support legacy spot.js.
                      setFacet({ ...facet, values: [...facet.values.filter((i, idx) => value.idx == null || idx < value.idx), ...value.value.map((v) => ({ value: typeof(v) == 'object' && v["*"] ? v : [v], name: typeof(v) == 'object' ? undefined : v })), ...facet.values.filter((i, idx) => value.idx != null && idx > value.idx)] }) 
                    }
                    setIsChanged(true);
                  }}
                  value={editingFacetValue}
                  footer={(fieldType === "string" || fieldType === "collection" || fieldType === "list") && (<Stack>
                    <Checkbox label="Group selected values" checked={editingFacetValue && editingFacetValue.name != null && editingFacetValue.name != '*'} onChange={(value) => setEditingFacetValue({ ...editingFacetValue, name: value ? '' : null })}/>
                    <Checkbox label="Show only unused values" checked={showUnusedValues} onChange={(value) => setShowUnusedValues(value)}/>
                  </Stack>)}
                />
                <MultiTable
                  sortable
                  resourceName={{ singular: "element", plural: "elements" }}
                  bulkActions={[{
                    content: "Delete elements",
                    onAction: (ids) => { setFacet({ ...facet, values: facet.values.filter((v, idx) => ids.indexOf(idx) == -1) }); setIsChanged(true); }
                  }]}
                  headings={[
                    "Label",
                    ...(fieldType === "numeric" || fieldType === "date" ? ["From", "To"] : ["Values"])
                  ]}
                  rows={
                    sortedFacetValues !== undefined &&
                    sortedFacetValues ? sortedFacetValues.map((facetValue, idx) => {
                      const [from, to] = convertStringToRange(facetValue.value);
                      return fieldType === "numeric" || fieldType === "date"
                        ? [
                          <SwatchText swatch={swatches && swatches.filter((s) => s.id == facetValue.swatch_id)[0]}>{localeFormat(locale, facetValue.name) ?? '*'}</SwatchText>,
                          fieldType === 'date' ? (facet.relative ? getRelativeDateLabel(from) : (from ? toLocaleDate(from * 1000) : "The Big Bang")) : from,
                          fieldType === 'date' ? (facet.relative ? getRelativeDateLabel(to) : (to ? toLocaleDate(to * 1000) : "Heat Death")) : to
                        ]
                        : [
                          <SwatchText swatch={swatches && swatches.filter((s) => s.id == facetValue.swatch_id)[0]}>{localeFormat(locale, facetValue.name) ?? '*'}</SwatchText>,
                          <LocalizedBadgeList values={(arrayify(facetValue.value).map((fv) => {
                            const name = fv.name || fv;
                            if (typeof(name) != 'object')
                              return name;
                            if (name['*'] == '*')
                              return "Wildcard [*]";
                            return `Starts with Wildcard [${name["*"]}]`;
                          }))} maxToDisplay={maxFacetValuesShown}/>
                        ];
                    }) : []
                  }
                  onRowClick={(row, idx) => { setEditingFacetValue({ ...sortedFacetValues[idx], idx: facet.values.indexOf(sortedFacetValues[idx]) }); }}
                  onDragDrop={(!facet.sort || facet.sort == "manual") && ((source, destination, length) => { setFacet({ ...facet, values: rearrangeArray(facet.values, source, destination, length) }); setIsChanged(true); })}
                />
              </Card>)}
            </Layout.Section>
            <Layout.Section secondary>
              {groups && groups.length > 0 && <Card title='Groups' sectioned>
                <OptionList
                  selected={facet.groups}
                  onChange={(val) => { setIsChanged(true); setFacet({ ...facet, groups: val }); } }
                  options={groups.map((group, idx1) => { return { label: group.name, value: group.id} })}
                  allowMultiple
                />
              </Card>}
              {profile.shop.locales && profile.shop.locales.length > 1 && <Card title='Locales' sectioned>
                <OptionList
                  selected={facet.locales}
                  onChange={(val) => { setIsChanged(true); setFacet({ ...facet, locales: val }); }}
                  options={profile.shop.locales.map((locale, idx1) => { return { label: locale.name, value: locale.code} })}
                  allowMultiple
                />
              </Card>}
              {fieldType == "string" && field.handle != "tag" && facet.values && <Card sectioned title={<Stack alignment="center">
                <Heading>Unassigned Warnings</Heading>
                <Tooltip content="Specifies whether you want this facet to have warnings about if there any values that aren't assigned to a particular category.">
                  <Icon source={CircleInformationMajor} color="base"/>
                </Tooltip>
              </Stack>}>
                <ChoiceList
                  selected={[facet.orphans ? "1" : "0"]}
                  onChange={(val) => { setFacet({ ...facet, orphans: val[0] == "1" ? true : false }); setIsChanged(true); }}
                  choices={[
                    { label: "Warn", value: "1" },
                    { label: "Don't Warn", value: "0" }
                  ]}
                />
              </Card>}
            </Layout.Section>
          </Layout>
        );
      }}
    </Page>
  );
}
